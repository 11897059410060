<template>
  <div>
    <MyTable
      :columns="columns"
      :rows="rows"
      :date-range-enabled="true"
      :date-range-field="'date_added'"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'id'">
          <b-badge
            variant="primary"
            class="p-1 text-white font-weight-bolder"
          >
            {{ props.row.order.reference_id }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'date_added'">
          {{ $helpers.formatDateTime(props.row.date_added) }}
        </span>

        <span v-else-if="props.column.field === 'status.description'">
          <b-badge
            :variant="props.row.status.color_name"
            class="p-1 text-white font-weight-bolder w-100"
          >
            {{ props.row.status.text }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'actions'">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="25"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-for="(item, index) in status"
              :key="index"
              @click="handleCancellation(item.id, props.row)"
            >
              {{ item.text }} Request
            </b-dropdown-item>
          </b-dropdown>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import axios from '@axios'

export default {
  name: 'CancellationRequests',
  components: {
    MyTable,
  },
  data() {
    return {
      columns: [
        {
          label: 'Order ID',
          field: 'id',
          type: 'number',
          tdClass: 'text-nowrap align-middle text-left pl-1',
          thClass: 'text-left',
        },
        {
          label: 'Company\'s Name',
          field: 'user.name',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
        {
          label: 'Driver\'s Name',
          field: 'driver.full_name',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
        {
          label: 'Sender\'s Name',
          field: 'order.sender',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
        {
          label: 'Recipient\'s Name',
          field: 'order.recipient',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
        {
          label: 'Request Date',
          field: 'date_added',
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: 'Reason',
          field: 'reason',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status.description',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
      ],
      rows: [],
      isLoading: false,
      status: [
        { id: 1, text: 'Approve' },
        { id: 2, text: 'Decline' },
      ],
    }
  },
  created() {
    this.getOrders()
  },
  methods: {
    async getOrders() {
      this.isLoading = true
      const query = 'delivery-cancellations?for=admin'
      const response = await axios.get(query)
      this.rows = response.data
      this.isLoading = false
    },
    async handleCancellation(type, order) {
      const query = `${order.id}?&status=${type}`
      await axios.patch(`delivery-cancellations/${query}`)
      this.getOrders()
    },
  },
}
</script>

<style>

</style>
